<template>
  <div>
    <v-container
      class="pa-0"
      v-if="false"
    >
      <v-row
        align="center"
        dense
      >
        <v-col
          class="py-0"
          cols="12"
          sm=""
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-row class="pa-0">
                <v-col
                  cols="12"
                  sm="auto"
                  class="px-0"
                  v-if="work.standard_hours && work.standard_hours > 0"
                  :style="'width:' + (standardHoursPercent*100) + '%'"
                >
                  <div class="text-center caption">
                    {{ standardHoursFriendlyText }}
                  </div>
                  <div class="green py-1" />
                  <div class="text-center caption">
                    0 ISK
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm=""
                  class="px-0"
                  v-if="work.evening_hours && work.evening_hours > 0"
                  :style="'width:' + (eveningHoursPercent*100) + '%'"
                >
                  <div class="text-center caption">
                    {{ eveningHoursFriendlyText }}
                  </div>
                  <div class="orange py-1" />
                  <div class="text-center caption">
                    {{ '0 ISK' }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm=""
                  class="px-0"
                  v-if="work.weekend_hours && work.weekend_hours > 0"
                  :style="'width:' + (weekendHoursPercent*100) + '%'"
                >
                  <div class="text-center caption">
                    {{ weekendHoursFriendlyText }}
                  </div>
                  <div class="red py-1" />
                  <div class="text-center caption">
                    0 ISK
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm=""
        >
          <v-row>
            <v-col class="py-0">
              <div
                :class="$vuetify.breakpoint.xs ? 'text-center' : 'text-right'"
                class="subtitle-2"
              >
                {{ totalHoursFriendlyText }}
              </div>
            </v-col>
            <v-col class="py-0">
              <div class="text-center subtitle-2">
                0 ISK
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="true">
      <div class="d-flex flex-row">
        <div
          class="flex-grow-1 flex-shrink-0"
          v-if="work.standard_hours && work.standard_hours > 0"
        >
          <div class="text-center caption">
            {{ standardHoursFriendlyText }}
          </div>
          <div class="green pa-1" />
          <div class="text-center caption">
            0 ISK
          </div>
        </div>
        <div
          class="flex-grow-1 flex-shrink-0"
          v-if="work.evening_hours && work.evening_hours > 0"
        >
          <div class="text-center caption">
            {{ eveningHoursFriendlyText }}
          </div>
          <div class="orange pa-1" />
          <div class="text-center caption">
            0 ISK
          </div>
        </div>
        <div
          class="flex-grow-1 flex-shrink-0"
          v-if="work.weekend_hours && work.weekend_hours > 0"
        >
          <div class="text-center caption">
            {{ weekendHoursFriendlyText }}
          </div>
          <div class="red pa-1" />
          <div class="text-center caption">
            0 ISK
          </div>
        </div>
        <div class="flex-shrink-1">
          <div class="text-center subtitle-2 pa-3">
            {{ totalHoursFriendlyText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'WorkHoursIndicatorBar',
    props: {
      work: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
        convertHoursFractionToString(hourFractal) {
            let totalMinutes = hourFractal * 60
            var hours = Math.floor(totalMinutes / 60)
            var minutes = totalMinutes % 60
            return hours + "h " + Math.round(minutes) + "m"
        }
    },
    computed: {
        totalHours: function() {

            if(this.work.end_date === null) {

                const timeDiff = (Date.now() / 1000) - this.work.start_date
                const minutes = timeDiff / 60

                return minutes / 24
            }

            return this.work.total_hours
        },
        standardHoursPercent: function() {

            if(this.work.standard_hours === null || this.work.standard_hours === 0) {
                return 0
            }

            return this.work.standard_hours / this.work.total_hours
        },
        eveningHoursPercent: function() {

            if(this.work.evening_hours === null || this.work.evening_hours === 0) {
                return 0
            }

            return this.work.evening_hours / this.work.total_hours
        },
        weekendHoursPercent: function() {

            if(this.work.weekend_hours === null || this.work.weekend_hours === 0) {
                return 0
            }

            return this.work.weekend_hours / this.work.total_hours
        },
        standardHoursFriendlyText: function() {

            if(this.work.standard_hours === null) {
                return 0
            }

            return this.convertHoursFractionToString(this.work.standard_hours)
        },
        eveningHoursFriendlyText: function() {

            if(this.work.evening_hours === null) {
                return 0
            }

            return this.convertHoursFractionToString(this.work.evening_hours)
        },
        weekendHoursFriendlyText: function() {

            if(this.work.weekend_hours === null) {
                return 0
            }

            return this.convertHoursFractionToString(this.work.weekend_hours)
        },
        totalHoursFriendlyText: function() {

            if(this.work.total_hours === null) {
                return 0
            }

            return this.convertHoursFractionToString(this.totalHours)
        }
    }
}
</script>
